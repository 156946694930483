import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { animated, SpringValue } from "react-spring";

interface TestimonialProps {
    quote: string;
    author: string;
    style?: Record<string, SpringValue>;
}

const Testimonial = ({ quote, author, style }: TestimonialProps) => {
    return (
        <animated.div style={style} className="testimonial">
            <FontAwesomeIcon icon={faQuoteLeft} /> {quote}
            <span className="author">{author}</span>
        </animated.div>
    );
};

export default Testimonial;
