import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faBoxesStacked,
    faBroom,
    faCar,
    faDumbbell,
    faFileInvoice,
    faHammer,
    faHelmetSafety,
    faPaw,
    faPeopleCarryBox,
    faTrashArrowUp,
    faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Modal from "./Modal";

interface Service {
    title: string;
    info: string;
    icon: IconProp;
}

const services = [
    {
        title: "Organization",
        info: "We can help organize your home or business and coach you to help strategize and stay organized in the future!",
        icon: faBoxesStacked,
    },
    {
        title: "Cleaning",
        info: "Professional cleaning services provided by our kind, judgment free, staff.",
        icon: faBroom,
    },
    {
        title: "Licensed Contractor",
        info: "We are a licensed contractor fully prepared to take on any projects you may have in your home or business.",
        icon: faHammer,
    },
    {
        title: "Construction",
        info: "Let us remodel your home!",
        icon: faHelmetSafety,
    },
    {
        title: "Account Management",
        info: "Managing accounts, filing paperwork, billing, tax and notary assistance.",
        icon: faFileInvoice,
    },
    {
        title: "Waste Removal",
        info: "We can remove trash and waste from your home or business.",
        icon: faTrashArrowUp,
    },
    {
        title: "Moving",
        info: "Moving can be difficult and we understand that. We can assist your move to make it a smooth transition.",
        icon: faPeopleCarryBox,
    },
    {
        title: "Driving",
        info: "Uber and Lyft can be very expensive. Let us drive you instead! Schedule ahead of time.",
        icon: faCar,
    },
    {
        title: "Workout",
        info: "Need a personal fitness coach? We can help with that as well!",
        icon: faDumbbell,
    },
    {
        title: "Plant-based Cooking",
        info: "We have years of experience with plant-based cooking. Let us elevate your kitchen with some new recipes!",
        icon: faUtensils,
    },
];

const Services = () => {
    const [service, setService] = useState<Service | undefined>(undefined);
    return (
        <div className="main">
            <h1>Services</h1>
            <div className="services">
                {services.map((svc: Service) => (
                    <button
                        key={svc.title}
                        type="button"
                        className="service"
                        onClick={() => setService(svc)}
                    >
                        <FontAwesomeIcon icon={svc.icon} />
                        {svc.title}
                    </button>
                ))}
            </div>
            <Modal id="service" show={service !== undefined}>
                <div className="button-bar">
                    <button
                        type="button"
                        className="exit"
                        onClick={() => setService(undefined)}
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    <h2 className="logo">{service?.title}</h2>
                    <p>{service?.info}</p>
                </div>
            </Modal>
        </div>
    );
};

export default Services;
