import Reorganize from '../assets/Reorganize.jpg';
import Renew from '../assets/Renew.jpg';
import Renovate from '../assets/Renovate.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Home = () => {
    return (
        <div>
            <div className="logo floating">
                3R's
            </div>
            <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay interval={5000} transitionTime={1000}>
                <div>
                    <img src={Reorganize} alt="Reorganize" />
                    <h1>Reorganize</h1>
                </div>
                <div>
                    <img src={Renew} alt="Renew" />
                    <h1>Renew</h1>
                </div>
                <div>
                    <img src={Renovate} alt="Renovate" />
                    <h1>Renovate</h1>
                </div>
            </Carousel>
        </div>
    );
};

export default Home;
