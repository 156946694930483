import "./styles/App.scss";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Testimonials from "./components/Testimonials";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Services from "./components/Services";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const App = () => {
    const [show, setShow] = useState(false);
    return (
        <div>
            <nav className="desktop">
                <Link className="logo" to="/">
                    3R's
                </Link>
                <Link onClick={() => setShow(false)} to="/services">
                    Services
                </Link>
                <Link to="/testimonials">Testimonials</Link>
                <Link to="/about">About Us</Link>
                <a href="https://instagram.com/reorganizerenovaterenew">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
            </nav>
            <nav className="mobile">
                <button
                    type="button"
                    onClick={() => setShow(!show)}
                    className="menuBtn"
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <Link className="logo" onClick={() => setShow(false)} to="/">
                    3R's
                </Link>
            </nav>
            {show && (
                <ul className="dropdown">
                    <Link onClick={() => setShow(false)} to="/">
                        <li>Home</li>
                    </Link>
                    <Link onClick={() => setShow(false)} to="/services">
                        <li>Services</li>
                    </Link>
                    <Link onClick={() => setShow(false)} to="/testimonials">
                        <li>Testimonials</li>
                    </Link>
                    <Link onClick={() => setShow(false)} to="/about">
                        <li>About Us</li>
                    </Link>
                    <a href="https://instagram.com/reorganizerenovaterenew">
                        <li>
                            <FontAwesomeIcon icon={faInstagram} />{' '}
                            Instagram
                        </li>
                    </a>
                </ul>
            )}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/about" element={<About />} />
                <Route path="/testimonials" element={<Testimonials />} />
            </Routes>
        </div>
    );
};

export default App;
