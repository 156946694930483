const About = () => {
    return (
        <div className="main">
            <h1>About Us</h1>
            <p>
                Hello Everyone! We are Sierra and Freddy based in New Jersey,
                and we would love to Help You!
            </p>
            <p>We serve most of the Tri-State area as well as Pennsylvania!</p>
            <p>
                We are a licensed contractor that specializes in getting
                projects done! Contact us for any renovations or construction
                needs! We also specialize in home organization. We can take any
                mess, clutter or overwhelming chaotic situation and turn it into
                a simplified, organized, and well put together space.
            </p>
            <p>
                We are professional organizers and have tremendous experience
                creating Efficient, Comfortable and Happy spaces. We are fully
                aware of the difficulty one can experience when relocating,
                renovating, or letting go of things. We will personally work
                with you at whatever pace works best for you. We will treat your
                space and things with the utmost care. We will provide plans and
                recommendations to guide you through the process. We will help
                set up systems to keep you organized as well as coach you on
                letting go of what you can. We can bring items you no longer
                need to be donated, and we can also remove and recycle your
                waste.
            </p>
            <p>
                Contact us if you need Personal Assistance getting your
                paperwork and files in order, accounts managed, calls made,
                bookkeeping, filing, billing, financial, tax and notary
                assistance, copying, scanning, printing, computer work, photo
                organization/mounting/display/scrapbooking, digitizing documents
                or photos, and shredding documents so that your home has less
                paper!
            </p>
            <p>
                Freddy is also a tech wiz, he can install, mount, connect and
                show you how to use any electronic devices.
            </p>
            <p>
                You can count on our Reliable and Efficient Work paired with a
                Patient, Gentle, Kind and Judgement Free Service. Rates for
                these Services vary Case by Case, depending on the work and
                travel, but I assure you we can work with your budget!
            </p>
            <p>Please Inquire!</p>
            <p>
                Call us at 908-612-4574 or Email us at{" "}
                <a href="MAILTO:Lifeisgoodnaturally@gmail.com">
                    Lifeisgoodnaturally@gmail.com
                </a>
                . We look forward to hearing from you!
            </p>
        </div>
    );
};

export default About;
