import { PropsWithChildren } from "react";
import { animated, useTransition } from "react-spring";

interface ModalProps {
    id: string;
    show?: boolean;
    delay?: number;
    wide?: boolean;
}

const Modal = ({
    id,
    show = false,
    delay = 0,
    children,
    wide = false,
}: PropsWithChildren<ModalProps>) => {
    const transitions = useTransition(show, {
        from: {
            display: "none",
            opacity: 0,
        },
        enter: {
            display: "block",
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
        delay,
    });

    return transitions(
        (styles, display) =>
            display && (
                <animated.div
                    style={styles}
                    className="modal"
                    id={`${id}Modal`}
                >
                    <div
                        className={
                            wide ? "modal-content modal-wide" : "modal-content"
                        }
                        id={`${id}ModalContent`}
                    >
                        {children}
                    </div>
                </animated.div>
            )
    );
};

Modal.defaultProps = {
    title: undefined,
    body: undefined,
    footer: undefined,
    show: false,
    headerClassNames: [],
    delay: 0,
    wide: false,
};

export default Modal;
