import Testimonial from "./Testimonial";

interface Testimony {
    quote: string;
    author: string;
}

const testimonials: Testimony[] = [
    {
        quote: "Sierra is very personable and helpful. She has the organizational skills I lack and was able to help me make some headway in my disorganized life. She is very reliable and responsible. I look forward too continuing to work with her.",
        author: "Natasha",
    },
    {
        quote: "Freddy and Sierra do awesome work! My basement has been transformed from a storage room to a place to hang out with friends! They are awesome at organizing and cleaning as well. 10/10 would recommend them every time!",
        author: "Tommy",
    },
    {
        quote: "Freddy is the onsite technician for my company, Next Solutions. We install screens of all sizes to help promote items within a place of business. Freddy installs an array of hardware that provide us with remote access so that we may control the content output for every location that we work with. Freddy has worked for us in New York, Chicago and Florida. We will continue to send him everywhere this business takes us. He is a responsible hard worker and a very quick learner. He is able to figure out problems that others cannot. We are thankful to have Freddy on our team.",
        author: "Marty",
    },
    {
        quote: "I met Sierra and Freddy (The Three R's) almost 2 years ago when I decided my house needed more than a cleaning but some organizing. From day 1 they came with positive energy and awesome teamwork. With that being said I am and will always be a returning customer. ....FYI I have an appt. in about 2 weeks",
        author: "Tameka",
    },
    {
        quote: "Above and Beyond grateful I found Sierra and her husband. They do such a phenomenal job with everything they do. Working with one was gratifying imagine working with both! Was able to get all my projects done. I recommend them to any and everyone! Thanks guys!",
        author: "Jennifer",
    },
    {
        quote: "The best! The best! The best! I am so fortunate to have found this couple. They can fulfill all of my organizing/handyman needs. Sierra and Freddy both organize, however, I had so much to do Sierra organized and Freddy did the heavy lifting/fixing, etc. They're reliable, professional, efficient, talented, friendly and spiritually grounded. May I add their pricing is very reasonable. They came to my cluttered apartment where I had unsightly bins all around and furniture I either wanted to donate or throw out. Before I knew they got to work. I watched Sierra as she so happily emptied each bin, reorganized it and in the end reduced the storage to 3 or 4 less bins (you can tell she enjoys the work). Freddy was busy getting rid of my couch and other furniture etc. By the end of the day, my apartment was ready for decorating. I had them come back several times. They put up shelves, blinds, cornice, etc. Organized my tools, pots and pans, etc. I must give them credit for my renewed drive and re-awakened creative spirit. I have done so much with their help and inspiration. I am still looking to have them back. Freddy is going to paint and do a few other projects I may have. My apartment is now beautiful and I am no longer ashamed to have company. They can do it all. Do not hesitate to hire either both together or whichever you think may serve your needs best.",
        author: "Linda",
    },
    {
        quote: "Sierra is amazing! She helps me organize untidy areas of my apartment. After she deals with a shelf or cabinet, it becomes neat and clean and gives me a peace of mind. She is diligent, careful, with great attention to details. She is also super-fast! I cannot think as fast as she can organize. Unlike some other organizers, she is open to working on her own, which gives me time to work on my other projects while she is organizing. I can absolutely rely on her. Personality-wise, Sierra is extremely nice and easy-going. She offered me valuable advice on many occasions. I cannot be happier that I found Sierra!",
        author: "Sasha",
    },
    {
        quote: "Sierra has helped me purge and organize my clutter as well as tackle cleaning projects that got out of hand. She’s dependable, cheerful, smart as a whip and a hard-worker. She also possesses a good soul and great sense of humor; Both are greatly appreciated.",
        author: "Francesca",
    },
    {
        quote: "I needed my 50 inch TV mounted on my wall and a friend of mine recommended Fred for the job. He was attitude is positive and professionalism for mounted the tv was all on point. TV is still up with little to no debris.",
        author: "Michael",
    },
    {
        quote: "I have used Fred for personal and business help and I have to say he is extremely reliable, detail oriented and is an extremely hardworking individual. What is great with him is that he is a jack of all trades and fills in the gaps where you need things done by someone that can think I their feet. He also has the integrity and honesty to tell you if he can not do a specific task which saves you time as you would know how to allocate resources to a project. Fred is a joy to work with and I will continue to hire him in the future for all projects that are applicable.",
        author: "James",
    },
    {
        quote: "I needed help moving a TV stand I had recently purchased from Facebook Market. Fred was quick to respond, gave confirmation of pickup, and setup up a time and date for delivery. The delivery was fast and professional. Would definitely use him again.",
        author: "Diane",
    },
    {
        quote: "Great work, very responsive and reasonable rates. Triple R is a great team.",
        author: "Doug",
    },
    {
        quote: "Freddy completely refinished my kitchen. He did tile work on the walls, wooden floor installation, painting, demolition, basic plumbing work and also general help like cleaning and organizing. I call Fred anytime I am in need of any assistance and will continue to work with him in the future.",
        author: "Freddy",
    },
    {
        quote: "Freddy installed a huge television for me onto the wall. It looks great! While he did that his wife Sierra helped me reorganize my dresser drawers. This couple is a dynamic duo that works very well together!",
        author: "Ana",
    },
    {
        quote: "Freddy painted my entire home and did a phenomenal job! He works quickly and is a very kind, personable gentleman with very reasonable prices. Freddy also helped with other jobs around the home like putting up picture frames, a large mirror, changing light fixtures, and was able to resolve an issue with our toilet. If you have a need for an excellent helper, hire Fred!",
        author: "Veronica",
    },
    {
        quote: "Freddy and Sierra are the Super and Property Managers of my 3 large properties with multiple dwellings. Overall they care for over over 30 residential or commercial rentals. They collect the rent, find new tenants, complete leases, handle all billing, tax prep work and payments, evictions, court processes, tenant questions or issues and more. Freddy handles most repairs, installations, lock changes, construction, plumbing, electric work and more. This couple is a tremendous team that keeps all of my properties running smoothly.",
        author: "Adolfo",
    },
    {
        quote: "I am thrilled about the work that Sierra and her wonderful husband Freddy has helped me with in my home. They are so nice and go above and beyond on their tasks! They are trustworthy and totally reliable, my results are outstanding! I was overwhelmed trying to downsize and they . made the task easy and comfortable. I have used them several times and will again very soon! You can't go wrong!!!",
        author: "Susan",
    },
    {
        quote: "This married couple are who you would want to hire if you are moving or just want to put your life together and see organization all around you. We just moved after thirty three years in one place and they were able to sort through our collection of everything from furniture to kitchen and dinnerware, as well as other objects that we collected, and these two made our life sane! We threw out a lot, but that which we chose to keep they were able to carefully wrap and package for the next part of our lives. Not only are they extremely capable and honorable, but they are happy people to be around and unusually congenial to work with. What can I say more... we could not have made this move without their experience and expertise! They are indeed GREAT!",
        author: "Joyce and Norman Arnoff",
    },
    {
        quote: "I hired Sierra and Freddie to help organize my two properties about a month ago. They worked hard to set up a system that my tenants can follow. We are now remodeling my Carriage House. They are able to do all aspects of the remodel and I’m very happy with their work. I would highly recommend them.",
        author: "Jan Yucom",
    },
    {
        quote: "I used Freddy for my recent storage fiasco/ and move. He was great! He's very strong and super helpful. He also takes direction well, is sweet and will make sure you are feeling comfortable and stress free. Rates are reasonable and I would definitely hire him again!",
        author: "Dee Prouty",
    },
    {
        quote: "It's my pleasure to recommend the services of Sierra as an organizer. At first I was very reluctant to contact someone to help me clean up a messy home. I felt self conscious and could not see how a stranger would be able to help. Not having the motivation, physical energy or any idea of where to start with my mess, I made my first organizing appointment. With her I was able to break the ice and see an easy system to work through what needed to be done in my home. Within a very short time, I felt comfortable, I never felt judged or criticized and most importantly I felt hopeful. Although she was happy to work alone, I choose to work along side her. Basically she lead the way at the beginning. She gently showed me an easy system that would allow me to work through all the problem areas in my home. After every session, I felt like so much had been accomplished. It felt pretty amazing. As areas were cleaned up and organized, I began to feel comfortable and confident that I could do these things on my own. I know anyone seeking home organization like Sierra provides, will be happy they booked her. After working with her for a few sessions I was able to see clearly the things I needed to do. For me I needed to learn how to keep the many things I had in an orderly fashion. Working along side Sierra clearing out the cluttered areas of my home taught me what I needed to do to keep things from getting messy. Sierra is a responsible, trustworthy, friendly, highly motivated worker and individual who puts you at ease immediately. I cannot say enough good things about her and would highly recommend her to anyone needing a little or a lot of help getting their home in order.",
        author: "Connie Rios",
    },
    {
        quote: "It was a pleasure working with Sierra and I look forward to working with her again. I engaged her services on a number of occasions, most recently in November. She is an outstanding organizer, who completes her job on time with minimum fanfare. There aren't many people in whom I place absolute confidence. She is definitely one of those few.",
        author: "Thomas Sanchez",
    },
];

const Testimonials = () => {
    return (
        <div className="main">
            <h1>Testimonials</h1>
            <div className="testimonials">
                {testimonials.map((t: Testimony) => (
                    <Testimonial quote={t.quote} author={t.author} />
                ))}
            </div>
        </div>
    );
};

export default Testimonials;
